<template>
  <!-- dialog:numpad START -->
  <transition name="dialog-fade" mode="out-in">
    <div
      class="dialog-numpad dialog-numpad-default"
      v-if="dialogNumpadVisible"
    >
      <div class="dialog-numpad-default-container" v-if="!inactiveButton">
        <h4 v-html="$t('terminal.numpad.quantity.input')"></h4>

        <!-- dialog:numpad:display START -->
        <el-input
          class="quantity"
          type="number"
          inputmode="numeric"
          v-model="numpadForm.productQuantity"
        ></el-input>
        <!-- dialog:numpad:display END -->

        <!-- dialog:numpad:display:validation START -->
        <div
          class="quantity-validation-message"
          v-if="productQuantityMessageActive"
        >
          <div class="validation-message" v-html="productQuantityMessage"></div>
        </div>

        <!-- dialog:numpad:keys START -->
        <div class="numpad-holder-dialog">
          <div class="numpad-dialog">
            <div
              class="button"
              v-on:click="append('1')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >1</div>
            <div
              class="button"
              v-on:click="append('2')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >2</div>
            <div
              class="button"
              v-on:click="append('3')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >3</div>
            <div
              class="button"
              v-on:click="append('4')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >4</div>
            <div
              class="button"
              v-on:click="append('5')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >5</div>
            <div
              class="button"
              v-on:click="append('6')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >6</div>
            <div
              class="button"
              v-on:click="append('7')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >7</div>
            <div
              class="button"
              v-on:click="append('8')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >8</div>
            <div
              class="button"
              v-on:click="append('9')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >9</div>
            <div
              class="button remove"
              v-on:click="clear()"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >DEL</div>
            <div
              class="button"
              v-on:click="append('0')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >0</div>
            <div
              class="button o-k"
              :class="[
                { 'nofity' : numpadForm.productQuantity !== ''
                && Number(this.numpadForm.productQuantity) >= 1
                && Number(this.numpadForm.productQuantity) <= 99 }
              ]"
              v-on:click="add()"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >OK</div>
          </div>
        </div>
        <!-- dialog:numpad:keys END -->

      </div>
    </div>
  </transition>
  <!-- dialog:numpad END -->
</template>

<script>
export default {
  name: 'dialog-numpad',
  props: {
    dialogNumpadVisible: {
      type: Boolean,
      default: false,
    },
    addProductIDQuantity: {
      type: Function,
    },
    closeNumpad: {
      type: Function,
    },
  },
  data() {
    return {
      inactiveButton: false,
      productQuantityMessageActive: false,
      productQuantityMessage: this.$t('terminal.index.cart.numpad.maxlength'),
      dialogNumpadVisibleChild: false,
      numpadForm: {
        productQuantity: '',
      },
      rules: {
        productQuantity: [
          {
            minValue: 1,
            maxValue: 99,
            message: this.$t('terminal.index.cart.numpad.blank'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  methods: {
    append(q) {
      this.numpadForm.productQuantity += q.toString();

      // validate input field
      if (Number(this.numpadForm.productQuantity) <= 99) {
        this.productQuantityMessageActive = false;
      } else {
        this.productQuantityMessageActive = true;
      }
    },

    clear() {
      this.numpadForm.productQuantity = this.numpadForm.productQuantity
        .substr(0, this.numpadForm.productQuantity.length - 1);

      // validate input field
      if (Number(this.numpadForm.productQuantity) <= 99) {
        this.productQuantityMessageActive = false;
      } else {
        this.productQuantityMessageActive = true;
      }
    },

    add() {
      this.inactiveButton = true;
      setTimeout(() => {
        // validate input field
        if (Number(this.numpadForm.productQuantity) <= 99) {
          this.productQuantityMessageActive = false;
          this.closeNumpad(Number(this.numpadForm.productQuantity));
          setTimeout(() => {
            this.numpadForm.productQuantity = '';
            this.inactiveButton = false;
          }, 150);
          if (this.numpadForm.productQuantity === '') {
            this.closeNumpad();
          }
        } else {
          this.productQuantityMessageActive = true;
          setTimeout(() => {
            this.inactiveButton = false;
          }, 150);
        }
      }, 150);
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },
  },
};
</script>
